import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`Dear Students,`}</p>
    <p><strong parentName="p"><em parentName="strong">{`[AAA---Short debrief or grading status]`}</em></strong></p>
    <p>{`This week we start in on our final textbook, Aarron Walter's `}<em parentName="p">{`Designing for Emotion`}</em>{`.`}</p>
    <p>{`You also have deliverable D from project 2 due. Be sure to review the feedback I provided on your task flow diagrams and revise as you create the low fidelity wireframes and demo video. Also look back at feedback your were given on the corresponding deliverable for Project 1 and be sure to adjust up your approach as needed. Remember not to rush ahead into visual style for a few more weeks as we are intentionally working through the low fidelity, structural portions first.`}</p>
    <p>{`Please let me know if you have any questions or concerns!`}</p>
    <p>{`Best,
`}<strong parentName="p"><em parentName="strong">{`[BBB---Prof name]`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      